<script setup lang="ts">
  defineProps<{
    min: number
    max: number
    isDsnp?: boolean
  }>()

  const { getComponentContent } = Cms.use()

  const { content } = await getComponentContent('QuoteCostRangeSidebar')
</script>

<template>
  <HpSidebar>
    <div
      class="mb-4 mt-8 flex w-full flex-col items-center gap-y-3 rounded-heading bg-sky-100 px-3 py-4"
    >
      <HpIcon name="healthpilot-icon-simple" class="mb-2 scale-125" />
      <h2 class="text-2xl font-semibold">{{ content.ext.title }}</h2>
      <div class="text-3xl font-bold" v-if="!isDsnp">{{ formatUsd(min) }}-{{ formatUsd(max) }}</div>
    </div>
    <div class="body-text" v-html="isDsnp ? content.ext.dsnp_body : content.ext.body"></div>
  </HpSidebar>
</template>

<style scoped>
  .body-text {
    :deep(p) {
      @apply mb-4;
    }
  }
</style>
