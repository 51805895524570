import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'

export type RangeCalculatorType = 'percentage' | 'amount' | 'deciles'

export default class ExpectedCostsRange {
  static use() {
    const calcFns = computed<
      Record<RangeCalculatorType, (quote: QuotedPlanVm, args: any[]) => [number, number]>
    >(() => ({
      percentage(quote: QuotedPlanVm, args: any[]) {
        const value = quote.recommendation.medicalCost ?? 0
        const modifier = args[0]
        return [value - value * modifier, value + value * modifier]
      },
      amount(quote: QuotedPlanVm, args: any[]) {
        const value = quote.recommendation.medicalCost ?? 0
        const modifier = args[0]
        return [value - modifier, value + modifier]
      },
      deciles(quote: QuotedPlanVm, args: any[]) {
        const deciles = quote.recommendation.costs?.full?.services?.inNetwork?.deciles
        return [deciles![args[0]], deciles![args[1]]]
      }
    }))

    const { flag } = FeatureFlags.use()

    const config = computed(
      () => flag<{ type: RangeCalculatorType; args: any[] }>('cost-range-calculation').value
    )

    const getRange = (quote: QuotedPlanVm) => {
      const [min, max] = calcFns.value[config.value.type](quote, config.value.args)

      return [
        _floor(_clamp(min, +0, Number.POSITIVE_INFINITY), -2),
        _clamp(_ceil(max, -2), 0, quote.details.maxOutOfPocket ?? Number.POSITIVE_INFINITY)
      ]
    }

    return {
      getRange
    }
  }
}
